<template>
  <div class="box">
    <!-- 面包屑导航区 -->
    <el-breadcrumb>
      <el-breadcrumb-item>骑手管理</el-breadcrumb-item>
      <el-breadcrumb-item>帮买帮送配送规则设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="detail">
      <el-form label-width="120px" class="detail-form base-text" size="medium">
        <div class="jbtop">
          <div class="icon"></div>
          <div class="tit">配送费设置-帮买帮送</div>
        </div>
        <el-row>
          <el-col :span="6">
            <el-form-item label="配送费基础价" prop="businessName">
              <template>
                <div class="flex">
                  <el-input class="inline-ipt pl0"
                    v-model="detailForm.businessName"
                    placeholder="请输入"
                    clearable
                  ></el-input>
                  元
                </div>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="self-row">
              <el-input class="inline-ipt pl0"
                v-model="detailForm.linkPhone"
                placeholder="请输入"
                clearable
              ></el-input>
              公里以内基础价，每增加一公里增加
              <el-input class="inline-ipt"
                v-model="detailForm.linkPhone"
                placeholder="请输入"
                clearable
              ></el-input>
              元，超过
              <el-input class="inline-ipt"
                v-model="detailForm.linkPhone"
                placeholder="请输入"
                clearable
              ></el-input>
              公里，不支持用户选择
            </div>
          </el-col>
        </el-row>
        <el-row class="self-row form-intitle">
          物品重量基础配置
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="self-row">
              <el-input class="inline-ipt pl0"
                v-model="detailForm.linkPhone"
                placeholder="请输入"
                clearable
              ></el-input>
              kg以内基础价，每增加一kg增加
              <el-input class="inline-ipt"
                v-model="detailForm.linkPhone"
                placeholder="请输入"
                clearable
              ></el-input>
              元
            </div>
          </el-col>
        </el-row>
        <el-row class="self-row form-intitle">
          特殊时段配置
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="特殊时段系数" prop="businessName2">
              <template>
                <div class="flex">
                  <el-input class="inline-ipt pl0"
                    v-model="detailForm.businessName2"
                    placeholder="请输入"
                    clearable
                  ></el-input>
                  %
                </div>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col v-for="(item,index) in detailForm.daterangeList" :key="index" :span="8">
            <el-form-item :label="'特殊时段'+(index+1)" prop="stime1">
              <template>
                <div class="flex">
                  <el-date-picker v-model="item.daterange" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                    range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" @change="changeTime1" clearable>
                  </el-date-picker>
                </div>
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="self-row">
              <el-button type="text" @click="addtime">添加时段</el-button>
            </div>
          </el-col>
        </el-row>
        <div class="jbtop">
          <div class="icon"></div>
          <div class="tit">配送区域管理</div>
        </div>
        <el-row v-for="(item,index) in detailForm.groupList" :key="index">
          <el-col :span="8">
            <el-form-item label="店铺名称" prop="businessName3">
              <template>
                <div class="flex">
                  <el-input
                    v-model="item.name"
                    placeholder="请输入"
                    clearable
                  ></el-input>
                </div>
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="归属区域" prop="businessName4">
              <template>
                <div class="flex">
                  <el-input
                    v-model="item.area"
                    placeholder="请输入"
                    clearable
                  ></el-input>
                </div>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="self-row">
              <el-button type="text" @click="addgroup">添加分组</el-button>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="display: flex;justify-content: center;margin-top: 28px;">
            <el-button type="primary" @click="submit"
              >保存</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
data() {
  return {
      options: [
        {
          value: 1,
          label: "待审核",
        },
        {
          value: 2,
          label: "已通过",
        },
        {
          value: 3,
          label: "已拒绝",
        },
      ],
      detailForm: {
        daterangeList:[
          {
            daterange: []
          }
        ],
        groupList:[
          {
            name: '',
            area: ''
          }
        ]
      },
      showDialog:false,
      businessdetail:{},
      id:'',
  };
},
mounted() {
  // this.getList ()
},
methods: {
  change(e){
    console.log(e);
  },
  back(){
    this.$router.push({path:'/riderlist'})
  },
  //获取数据
  getList () {
    var that = this;
    that.$http.post("/business/list", that.queryInfo).then(function (response) {
      console.log(response.data.data);
      if (response.data.code == 200) {
        that.total = response.data.data.count;
        that.queryInfo.currPage=response.data.data.currPage
        that.tableData = response.data.data.data;
      }
    });
  },
  // 时段选择
  changeTime1(e){
    console.log('daterange',e,this.detailForm)
  },
  // 添加时段
  addtime(){
    this.detailForm.daterangeList.push({daterange:[]})
  },
  // 添加分组
  addgroup(){
    this.detailForm.groupList.push(
      {
        name: '',
        area: ''
      }
    )
  },
  submit(){
    var that = this;
    that.$http.post("/business/list", that.detailForm).then(function (response) {
      console.log(response.data.data);
      if (response.data.code == 200) {
      }
    });
  },
}
};
</script>

<style lang="less" scoped>
.box {
  .detail {
      /deep/.el-form-item__label{
        padding-right: 14px;
      }
      background-color: #fff;
      box-sizing: border-box;
      padding: 20px 0;
      .jbtop {
          background-color: #eef1f6;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          padding: 20px;
          margin: 0 10px;
          margin-bottom: 40px;
          .icon {
              width: 6px;
              height: 19px;
              border-radius: 10px;
              background-color: #f77f1f;
              margin-right: 10px;
          }
          .tit {
              font-weight: 700;
          }
          
          
      }
      .detail-top {
          padding: 5px;
          background-color: rgba(30, 152, 215, 0.14);
          font-size: 15px;
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          div {
              width: 150px;
              text-align: center;
          }
      }
      .detail-form{
        padding-right: 20px;
        &>.el-row{
          padding: 0 50px;
        }
        .avatar-uploader {
          width: 200px;
          height: 200px;
        }

        .avatar-uploader ::v-deep .el-upload {
          //background-color: #fbfdff;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }

        .avatar-uploader .el-upload:hover {
          border-color: #409eff;
        }

        .avatar-uploader-icon {
          border: 1px dashed #c0ccda;
          font-size: 28px;
          color: #8c939d;
          width: 148px;
          height: 148px;
          line-height: 148px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 99;
        }
      }
  }
  .tab {
      background-color: #fff;
      margin-top: 15px;
      padding: 15px
  }
}
.avatar {
    width: 178px;
    height: 178px;
    border: 1px solid #877b7b;
    border-radius: 6px;
    object-fit: scale-down;
  }
/deep/ .el-switch__label * {
  font-size: 12px;
}
.self-row{
  padding-left: 120px;margin-bottom: 22px;
}
.form-intitle{
  font-size: 16px;
  font-weight: bold;
}
.inline-ipt{
  width: 160px;
  display: inline-block;
  padding: 0 12px;
}
</style>
